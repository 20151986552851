<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>短信推送</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <fieldset class="item-wrapper" v-for="(item, index) in list">
          <legend>{{item.name}}</legend>
          <el-form label-width="100px">
            <el-form-item label="是否开启" >
              <el-switch v-model="item.status" active-value="1"
    inactive-value="0" active-color="#13ce66" inactive-color="#ddd"> </el-switch>
            </el-form-item>
            <el-form-item label="短信模板ID" >
              <el-input v-model="item.templateId" placeholder="模板ID" />
            </el-form-item>
            <el-form-item label="签名" >
              <el-input v-model="item.signName" placeholder="签名" />
            </el-form-item>
            <div style="text-align: right;">
              <el-button type="primary" @click="save(index)">保 存</el-button>
            </div>
          </el-form>
      </fieldset>

    </el-card>
  </div>
</template>
<script>
export default {
  data () {
    return {
      list: []
    }
  },
  created () {
    this.GetList();
  },
  methods: {
    GetList () {
      this.$api.QueryMessageSendList({}).then((res) => {
        if (res.code !== 200)  return this.$message.error('请求失败')
        res.data.map(o => o.status = o.status.toString())
        this.list = res.data
      })
    },
    save (index) {
      const data = this.list[index];
      this.$api.UpdateMessageSendById(data).then((res) => {
        if (res.code !== 200) return this.$message.error('保存失败：' + res.message)
        this.$message.success('保存成功')
        this.GetList()
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.item-wrapper{
  width: 50%;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
</style>
